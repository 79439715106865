.bookmark {
  position: relative;
  z-index: 99;
  margin-left: auto;
  margin-right: 10px;
  padding: 5px 14px 5px 10px;
  border: 0;
  white-space: nowrap;
  font-family: $font-display;
  font-size: 14px;
  line-height: 1;
  transition: box-shadow 0.4s linear;
  display: none;
  background-color: transparent;
  background-image: url('../../img/play-now-button.png');
  background-size: 100% 100%;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  color: $white;
  text-shadow: 1px 1px 2px black;

  > span {
    display: block;
    padding: 6px 5px;
  }

  svg {
    color: #dcaf3e;
    width: 15px;
    height: auto;
  }

  &:hover {
    box-shadow: 0 0 20px 2px rgba($gold, 0.65);
  }

  @media (min-width: $md) {
    font-size: 14px;

    svg {
      width: 20px;
    }
  }

  @media (min-width: $lg) {
    margin-left: 10px;
    font-size: 12px;
    display: block;
  }

  @media (min-width: $xl) {
    font-size: 13px;
  }

  @at-root .shareFooter & {
    z-index: 0;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    padding: 5px 17px 5px 10px;
    font-size: 18px;

    > span {
      padding: 18px 20px;
    }

    svg {
      width: 30px;
    }
  }
}

.bookmarkHelper {
  font-size: 1rem;
  line-height: 1.5;

  @media (min-width: $md) {
    font-size: 1.5rem;
  }
}

.menuOpen {
  .bookmark {
    display: block;
    position: fixed !important;
    bottom: 30px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

.shareFooter {
  .bookmark {
    display: block;
  }
}

.bookmarkHelper {
  img {
    vertical-align: middle;
  }
}