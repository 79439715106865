/** Config */
@import './common/mixins';
@import './common/fonts';
@import './common/variables';

/** External */
@import '~normalize.css/normalize.css';
@import '~hamburgers/_sass/hamburgers/hamburgers';

/** Common */
@import './common/global.scss';

/** Components */
@import './components/share';
@import './components/bookmark';
@import './components/cta';
@import './components/video';
@import './components/divider';
@import './components/modal';

/** Parials */
@import './partials/header';
@import './partials/footer';
@import './partials/hero';
@import './partials/lang-selector';
@import './partials/carousel';
@import './partials/about';
@import './partials/features';
@import './partials/faq';

/** Other */
@import './other/embed';
@import './other/videojs';

@import './common/util';
