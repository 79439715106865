.about {
  position: relative;
  background-color: $black;
}

.aboutBg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  opacity: 0.13;
  filter: blur(5px);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to top,
      $black 0%,
      rgba(Black, 0) 30%,
      rgba(Black, 0) 70%,
      $black 100%
    );
  }
}

.aboutDesc {
  display: flex;
  flex-wrap: wrap;
  max-width: $video-width;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $md) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &__col {
    width: 100%;

    &:last-child {
      text-align: center;
    }

    @media (min-width: $md) {
      &:first-child {
        width: 70%;
        padding-right: 2rem;
      }

      &:last-child {
        display: block;
        width: 30%;
        height: auto;
        margin-bottom: 0;

        img {
          margin-right: 0;
        }
      }
    }
  }
}
