.lang {
  position: absolute;
  left: 0;
  color: $gray;
  font-family: $font-body;
  text-align: center;

  @media (min-width: $lg) {
    position: relative;
    left: auto;
  }

  &.show {
    z-index: 99;
  }

  button.lang__selected {
    font-family: $font-body;
    font-size: 12px;
    color: $gray;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 5px 0;
    border-radius: 2px;
    text-shadow: rgba($black, 0.9) 0 1px 1px;

    @media (min-width: $md) {
      &:hover {
        opacity: 0.9;
      }
    }

    &:focus {
      outline: none;
    }

    svg {
      margin: 0 0 2px 1px;
      vertical-align: middle;
    }

    &.opened {
      opacity: 0.85;

      svg {
        transform: rotate(180deg) !important;
      }
    }
  }

  .langList {
    padding: 7px;
    margin: 0;
    list-style: none;
    text-align: left;
    box-shadow: 0 4px 20px rgba($black, 0.8);

    .langList__item {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      button {
        @extend .lang__selected;
        background-color: transparent;

        @media (min-width: $md) {
          &:hover {
            color: $gold;
          }
        }
      }
    }
  }

  .bubble {
    position: fixed;
    margin: 0.7em 0 0 0;
    border: 1px solid rgba($gray, 0.9);
    background-color: rgba($black, 0.85);
    display: none;

    &.show {
      display: block !important;
    }

    &::before {
      content: '';
      position: absolute;
      top: -7px;
      bottom: auto;
      left: 50%;
      border-width: 0 7px 7px;
      border-style: solid;
      border-color: rgba($gray, 0.9) transparent;
      transform: translateX(-50%);
      display: block;
      width: 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: -5px;
      left: 50%;
      border-width: 0 5px 5px;
      border-style: solid;
      border-color: rgba($black, 0.85) transparent;
      display: block;
      width: 0;
      bottom: auto;
      transform: translateX(-50%);
    }
  }
}
