.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 80;
  width: 100%;
  background-color: $black;

  .container {
    max-width: 1400px;
  }

  &__inner {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__logoWrap {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 50;
    height: auto;
    width: 120px;
    background-color: $black;

    @media (min-width: 360px) {
      width: 150px;
    }

    @media (min-width: $lg) {
      position: relative;
      left: auto;
      transform: none;
    }

    @media (min-width: $xl) {
      width: 180px;
    }
  }

  &__logo {
    width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
  }

  &__nav {
    position: fixed;
    z-index: 50;
    height: 100%;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background-color: rgba($black, 0.97);
    text-align: center;
    opacity: 0;
    visibility: hidden;

    @media (min-width: $lg) {
      position: relative;
      display: block;
      top: 0;
      width: auto;
      margin-left: 20px;
      transform: none;
      opacity: 1;
      visibility: visible;
    }

    ul {
      position: relative;
      top: 45%;
      transform: translateY(-50%);
      margin: 0;
      padding: 0;
      list-style: none;
      color: $white;

      @media (min-width: $lg) {
        display: flex;
        top: auto;
        transform: none;
      }
    }

    li {
      padding: 0 10px;
      font-size: 0.9em;

      @media (min-width: $lg) {
        font-size: 0.5em;
        padding: 0 5px;

        &.is-current a {
          color: $gold;
        }
      }

      @media (min-width: 1080px) {
        font-size: 0.6em;
      }

      @media (min-width: 1154px) {
        padding: 0 10px;
      }

      @media (min-width: 1200px) {
        font-size: 0.7em;
      }

      @media (min-width: 1300px) {
        font-size: 0.8em;
      }
    }

    a {
      display: block;
      padding: 12px 0;
      font-family: $font-display;
      text-decoration: none;

      @media (min-width: $md) {
        padding: 22px 0;

        &:hover {
          color: $gold;
        }
      }
    }
  }

  &__share {
    position: fixed;
    bottom: 90px;
    z-index: 50;
    left: 50%;
    transform: translateX(-50%);
    margin-left: auto;
    opacity: 0;
    width: 100%;
    text-align: center;

    @media (min-width: $lg) {
      display: block;
      position: relative;
      bottom: auto;
      left: auto;
      transform: none;
      opacity: 1;
    }

    @media (min-width: $lg) {
      width: auto;
    }
  }

  &.menuOpen {
    .header__nav,
    .header__share {
      opacity: 1 !important;
    }

    .header__nav {
      visibility: visible !important;
    }
  }
}

.hamburger {
  z-index: 99;
  margin-left: auto;

  @media (min-width: $lg) {
    display: none;
    margin-left: 0;
  }

  &:focus {
    outline: none;
  }
}

.hamburger-box {
  top: 2px;
}

.App.lang-en {
  .header__nav {
    li {
      @media (min-width: $lg) {
        font-size: 1rem;
      }
    }
  }
}

.App.lang-it,
.App.lang-fr,
.App.lang-de,
.App.lang-tr {
  .header__nav {
    li {
      @media (min-width: $lg) {
        font-size: 14px;
      }
    }
  }
}
