// Video
.video {
  margin: 0 auto;
  padding-top: 2rem;
  text-align: center;

  @media (min-width: $md) {
    padding-top: 4rem;
  }

  .video__title {
    margin-bottom: 40px;
  }

  .vjs-big-play-button {
    height: 1.7em !important;
    width: 1.7em !important;
    line-height: 1.6 !important;
    font-size: 5em !important;
    border-color: transparent !important;
    color: rgba($gold, 0.9) !important;
    background-color: rgba($black, 0.8) !important;
    border-radius: 50% !important;
    margin-top: -0.8em !important;
    margin-left: -0.8em !important;
  }

  .video-js:hover .vjs-big-play-button {
    transition: all 0.2s ease !important;
    border-color: transparent !important;
    color: $gold !important;
    background-color: $black !important;
    box-shadow: 0 0 20px 2px rgba($gold, 0.9);
  }
}
