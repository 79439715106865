.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  marginright: -50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 800px;
  background-color: $dark;
  border-radius: 0;
  border: 2px solid $gold;
  padding: 20px;
  color: $white;
  font-family: $font-body;
  box-shadow: 0 10px 30px rgba($black, 0.8);

  &:focus {
    outline: $gold;
  }

  @media (min-width: $lg) {
    padding: 60px;
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.9);
  z-index: 999;
}

.modalClose {
  margin-top: 40px;
  color: $white;
  font-family: $font-display;
  padding: 20px;
  appearance: none;
  border: 0;
  background-color: transparent;
}
