// Features
.featureDesc {
  max-width: 950px;
  margin-bottom: 3rem;
}

.featureSubTitle {
  display: block;
  margin-top: 10px;
  color: lighten($gold, 23%);
  font-family: $font-display;
  font-size: 1.4rem;

  @media (min-width: $md) {
    margin-top: 4px;
  }
}

.featureImgContainer {
  @media (min-width: $lg) {
    display: flex;
  }
}

.featureImgWrap {
  position: relative;
  width: 100%;
  margin-right: 1rem;
  margin-bottom: 1rem;

  @media (min-width: $lg) {
    width: 50%;
  }

  > div {
    padding-top: 62%;
    background-color: $dark;
  }
}

.featureImg {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
}

// Extra features
.extraFeatures {
  position: relative;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  margin-left: -$spacer;
  margin-right: -$spacer;
  padding: 30px 0;
}

.bgPattern {
  position: relative;
  z-index: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-image: linear-gradient(
    to right,
    #0e0610 0%,
    #3e1e49 50%,
    #0e0610 100%
  );

  @media (min-width: $md) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to top,
      $black 0%,
      rgba(#3e1e49, 0) 50%,
      $black 100%
    );
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: url('../../img/pattern.png');
    background-size: 90px;
    opacity: 0.47;
  }
}

.extraFeature {
  width: 100%;
  margin: 0 0 2.5rem;
  text-align: center;
  padding: 0 $spacer;

  @media (min-width: $md) {
    margin: 0 0 1rem;
    width: 33.333%;
  }
}

.extraFeatureTitle {
  margin: 1.5rem auto 0;
  max-width: 80%;
  font-size: 26px;
  font-family: $font-display;
  text-shadow: 0 2px 5px rgba($black, 0.8);

  @media (min-width: 400px) {
    font-size: 28px;
  }

  @media (min-width: $md) {
    max-width: 100%;
    font-size: 22px;
  }

  @media (min-width: $lg) {
    font-size: 30px;
  }
}

.extraFeatureImgWrap {
  position: relative;
  width: 80%;
  height: auto;
  margin: 0 auto;

  @media (min-width: $lg) {
    width: 90%;
  }

  > div {
    padding-top: 87%;
  }
}
