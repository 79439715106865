.shareList {
  padding: 0;

  &__item {
    display: inline-block;
    height: auto;
    width: 30px;
    margin: 0 20px;
    vertical-align: middle;

    @media (min-width: $md) {
      width: 25px;
    }

    @media (min-width: $lg) {
      margin: 0 10px;
    }

    svg {
      width: 100%;
    }
  }

  &__button {
    @media (min-width: $lg) {
      &:hover {
        opacity: 0.85;
        cursor: pointer;
      }
    }

    &:focus {
      outline: none !important;
    }

    svg {
      vertical-align: middle;
    }
  }
}

.share {
  text-align: center;
  padding-top: 60px;

  &.shareFooter {
    padding-top: 0;

    .shareList {
      padding: 0;

      .shareList__item {
        width: 45px;
      }
    }
  }
}
