.carousel {
  min-height: 500px;
}

.carouselCell {
  width: 100%;
  height: 500px;
}

@media (min-width: $md) {
  .featureSlider {
    margin-top: 4rem;
  }
}
