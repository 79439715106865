.titleLg {
  font-size: 2em;
  margin-bottom: 1rem;

  @media (min-width: $md) {
    font-size: 2em;
  }

  @media (min-width: $lg) {
    font-size: 3em;
  }
}

.extraMargin {
  margin-bottom: 2rem;

  @media (min-width: $md) {
    margin-bottom: 4rem;
  }
}

.textCenter {
  text-align: center;
}

.imgFluid {
  max-width: 100%;
  height: auto;
}

.text--lg {
  font-size: 28px;

  @media (min-width: $md) {
    font-size: 36px;
  }
}

.text--gold {
  color: $gold;
}

.lead {
  font-size: 18px;
  line-height: 1.5;

  @media (min-width: $md) {
    font-size: 19px;
  }

  @media (min-width: $lg) {
    font-size: 21px;
  }
}

.text--gradient {
  background: linear-gradient(
    0.25turn,
    rgba($white, 0.5) 10%,
    $white 40%,
    $white 50%,
    $white 60%,
    rgba($white, 0.5) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 80px 6px rgba($white, 0.6);
    width: 50%;
    height: 0;
  }
}

.text--gradient-share {
  background: linear-gradient(
    0.25turn,
    rgba($white, 0.5) 10%,
    rgba($white, 0.5) 25%,
    $white 50%,
    rgba($white, 0.5) 75%,
    rgba($white, 0.5) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 80px 4px rgba($white, 0.6);
    width: 50%;
    height: 0;
  }
}

.text--gradient-trailer {
  background: linear-gradient(
    0.25turn,
    rgba($white, 0.5) 10%,
    rgba($white, 0.5) 40%,
    $white 45%,
    $white 50%,
    $white 55%,
    rgba($white, 0.5) 60%,
    rgba($white, 0.5) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 80px 6px rgba($white, 0.6);
    width: 50%;
    height: 0;
  }
}

.text--md {
  font-size: 22px;

  @media (min-width: $md) {
    font-size: 26px;
  }
}

.iconSvg {
  vertical-align: middle;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.aspectRatioSquare {
  position: relative;
  padding-top: 100%;
  width: 100%;
}

.aspectRatioLogo {
  position: relative;
  padding-top: 65%;
  width: 100%;
}

.imgFull {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.mt-0 {
  margin-top: 0 !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}
