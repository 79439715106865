* {
  box-sizing: border-box;
}

body,
html {
  overflow-x: hidden;
}

body {
  background-color: $black;
  font-size: 100%;
  -webkit-text-size-adjust: none;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba($black, 0.01) 0 0 1px;
}

main {
  padding-top: 50px;

  @media (min-width: $lg) {
    padding-top: 62px;
  }
}

@media (min-width: $lg) {
  html[lang='fr'] .features__2 .features__col p {
    padding-right: 5px;
  }
}

a {
  color: $gray;

  &:hover {
    color: lighten($gold, 10%);
  }
}

h1,
h2,
h3 {
  margin-top: 0;
  color: $white;
  font-family: $font-display;
}

p,
small {
  font-family: $font-body;
}

p {
  margin-top: 0;
  font-size: 16px;
  color: $gray;
  line-height: 1.7rem;
}

h1 {
  font-size: 24px;
  margin-bottom: 0;

  @media (min-width: $md) {
    line-height: 44px;
    font-size: 38px;
  }
}

input,
button {
  appearance: none;
}

button {
  cursor: pointer;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 $spacer;
}

.cta,
.features,
.faq {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.PlayNowBtn {
  background: url('../../img/play-now-button.png');
  background-size: 100% 100%;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  min-width: 70%;
  cursor: pointer;
  display: inline-block;

  @media (min-width: $md) {
    min-width: 50%;
  }

  @media (min-width: $lg) {
    min-width: 30%;
  }

  h1 {
    margin: 1em 1em 0.85em 1em;
    font-size: 1.6em;
    text-shadow: 1px 1px 2px black;

    @media (min-width: $md) {
      font-size: 2em;
      margin: 0.85em 0.85em 0.7em 0.85em;
    }
  }
}

.facebook_container {
  text-align: center;
}

.logoClickHandler {
  width: 60%;
  margin: 0 auto;
  padding-top: 22%;
  cursor: pointer;

  @media (min-width: $lg) {
    padding-top: 17%;
  }
}

/**
* Lazysizes
*/

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 400ms;
}

.blurUp {
  filter: blur(20px);
  opacity: 0;
  transition: opacity 1000ms, filter 1500ms;

  &.lazyloaded {
    filter: blur(0);
  }
}

.banner {
	padding: .25em;
	background-color: gold;
	color: black;
}
.bannerHeader { 
	display: block; 
	padding-left: .5em;
  font-family: $font-body;
  font-weight: 500;
	font-size: .7rem;
  text-align: center;
  color: black
}

.bannerHeader a, .bannerHeader a:hover { 
  color: black
}
