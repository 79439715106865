/** Grid */
$sm: 481px;
$md: 768px;
$lg: 1025px;
$xl: 1281px;

$spacer: 15px;
$video-width: 900px;

/** Color */
$black: #000;
$dark: #111;
$white: #fff;
$gray: #d4d5d5;
$gold: #d89e0f;
$light: #d299072c;

/** Typograph */
$font-display: 'TrajanPro-Bold', serif;
$font-body: 'Gotham-Medium', sans-serif;

/** Hamburgers */
$hamburger-padding-x: 0;
$hamburger-padding-y: 15px;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 5px;
$hamburger-layer-color: $gold;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 0.7;
$hamburger-active-layer-color: $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

$hamburger-types: (slider) !default;
