@mixin fontFace(
  $family,
  $src,
  $weight: normal,
  $style: normal
) {
  @font-face {
    font-family: $family;
    font-display: auto;
    src: url('#{$src}.eot');
    src: url('#{$src}.eot?#iefix')
        format('embedded-opentype'),
      url('#{$src}.woff2') format('woff2'),
      url('#{$src}.woff') format('woff'),
      url('#{$src}.svg##{$family}') format('svg'),
      url('#{$src}.ttf') format('truetype');
    font-style: $style;
    font-weight: $weight;
  }
}
