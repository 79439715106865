// CTA
.headingMain {
  text-align: center;
  font-size: 17px;
  line-height: 1.2;
  margin-bottom: 0;
  padding: 0 0.3rem;

  @media (min-width: $md) {
    line-height: 44px;
    font-size: 31px;
  }
}

.headingMain--lg {
  display: block;
  margin: 20px auto 7px;
  font-size: 22px;
  line-height: 1.2;
  max-width: 90%;
  color: $gold;
  text-shadow: 0 0 2px $light, 0 0 10px $light, 0 0 20px rgba($gold, 0.5),
    0 0 30px rgba($gold, 0.5), 0 0 40px rgba($gold, 0.5),
    0 0 50px rgba($gold, 0.5);
  animation: blink 1.5s infinite alternate;

  @media (min-width: $md) {
    font-size: 30px;
  }

  @media (min-width: $lg) {
    max-width: 80%;
    margin: 20px auto;
    line-height: 1.2;
    font-size: 53px;
  }
}

@keyframes blink {
  0% {
  }
  100% {
    text-shadow: 0 0 2px $light, 0 0 10px $light, 0 0 20px $light,
      0 0 40px rgba($gold, 0.5), 0 0 70px rgba($gold, 0.5),
      0 0 80px rgba($gold, 0.5);
  }
}

.cta__footnote p {
  margin-top: 1rem;
  text-align: center;
  font-size: 13px;
  line-height: 1.5;
  padding: 0 50px;
}

// Store Buttons
.storeBtns {
  text-align: center;
  margin: 50px 0 25px;
  cursor: pointer;

  @media (min-width: $md) {
    display: flex;
    justify-content: center;
  }

  &__item {
    display: block;
    height: 63px;
    margin-bottom: 1.3rem;

    @media (min-width: $md) {
      margin-bottom: 0;
      margin-right: 30px;

      &:first-child {
        margin-bottom: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    img {
      height: inherit;
      width: 200px;

      @media (min-width: $md) {
        height: 45px;
        width: 150px;
      }

      @media (min-width: $lg) {
        height: 55px;
        width: auto;
      }
    }
  }
}
