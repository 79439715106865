.divider {
  margin: 0 auto;
  height: 2px;
  width: 100%;
  max-width: 1000px;
  background-color: $white;
  background: linear-gradient(
    0.25turn,
    transparent,
    $white,
    transparent
  );
  position: relative;

  @media (min-width: $md) {
    margin: 0 auto;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 120px 5px rgba($white, 0.6);
    width: 60%;
    height: 0;
  }
}

.dividerWrap {
  padding: 50px 0;
  background: $black;

  @media (min-width: $md) {
    padding: 70px 0;
  }

  &#{&}--sm {
    padding: 10px 0;

    @media (min-width: $md) {
      padding: 20px 0;
    }
  }
}
