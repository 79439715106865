@include fontFace(
  'TrajanPro-Bold',
  '../../fonts/TrajanPro-Bold',
  bold
);

@include fontFace(
  'Gotham-Medium',
  '../../fonts/Gotham-Medium',
  500
);
