.footer {
  padding: 130px $spacer 20px;
  text-align: center;

  .footer__logos {
    margin-bottom: 27px;

    img {
      display: inline-block;
      margin: 0 10px;
      vertical-align: middle;
      height: 40px;
    }
  }

  small,
  small p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: block;
    color: $gray;
    line-height: 1.2 !important;
    font-size: 11px;

    @media (min-width: $md) {
      font-size: 14px;
    }
  }

  small p {
    margin: 1rem 0 0;
  }
}
