.hero {
  position: relative;
  padding: 90% 0 0;

  @media (min-width: $md) {
    padding: 43% 0 1%;
  }

  @media (min-width: $lg) {
    padding: 12% 0 1%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba($black, 0) 0%,
      rgba($black, 0) 40%,
      $black 95%
    );
  }

  .headingMain {
    margin-top: 2rem;

    h2 {
      margin-bottom: 8px;
    }

    span {
      margin-bottom: 0;
    }
  }

  .headingMain--lg {
    text-shadow: 0 0 2px $black, 0 0 10px $black, 0 4px 10px $black,
      0 4px 10px $black, 0 0 20px $black, 0 0 30px $black, 0 0 40px $black,
      0 0 50px $black !important;
  }

  .cta__footnote {
    margin-top: 0;
    text-shadow: 0 0 2px $black, 0 0 10px $black, 0 0 10px $black,
      0 0 10px $black, 0 0 10px $black, 0 0 20px $black, 0 0 30px $black,
      0 0 40px $black, 0 0 40px $black, 0 0 50px $black !important;

    p {
      margin-top: 0;
    }
  }
}

.heroContent {
  position: relative;
  z-index: 10;
}

.heroBg,
.heroBgImg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: $dark;
}

.heroBgImg {
  position: absolute !important;
  background-size: cover;
  background-position: center top;
  opacity: 0;
  transition: opacity 0.4s linear;

  &.active {
    opacity: 1;
  }
}

.heroBgMobile {
  @media (min-width: $md) {
    display: none !important;
  }
}

.heroBgDesktop {
  display: none;

  @media (min-width: $md) {
    display: block !important;
  }
}
